import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import HoverIntent from 'react-hoverintent';
import { menuData } from './menuData';
import './navigation.scss';
import { CloseXIcon } from '../../../../UI/Icons/Icons';

export class Navigation extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.sortCats = this.sortCats.bind(this);
		this.showHideStickyMask = this.showHideStickyMask.bind(this);
	}

	showHideStickyMask() {
		if (window.IntersectionObserver) {
			var headerWrapper = document.getElementById('header_desktop_wrapper');
			var observer = new IntersectionObserver((entries, observer) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							document.getElementById('stickyMask').classList.remove('showStickyMask');
							document.getElementById('menu_wrapper').classList.remove('dropShadow');
						} else {
							document.getElementById('stickyMask').classList.add('showStickyMask');
							document.getElementById('menu_wrapper').classList.add('dropShadow');
						}
					});
			}, {rootMargin: '0px'});
			observer.observe(headerWrapper);
		}
	}

	componentDidMount() {
		this.props.NavMegaMenuData();
		if (this.props.passedAppFunctions.siteFormat == 'desktop') {
			this.showHideStickyMask();
		}
	}

	adaskipNavigation() {
		$('.header_promo').focus().css('outline-color', 'transparent');
	}

	formatURLsIfNeeded(href) {
		if (href.indexOf('dillards.com') > -1) {
			href = href.split('dillards.com')[1];
		}
		return href;
	}
	sortCats(property) {
		var sortOrder = 1;
		return function (a, b) {
			var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
			return result * sortOrder;
		};
	}

	render() {
		var megaMenuData = this.props.megaMenuState.superCats;

		return (
			<React.Fragment>
				{this.props.passedAppFunctions.siteFormat == 'desktop' ? <div id='stickyMask'></div> : ''}
				<nav id='menu_wrapper'>
					{this.props.passedAppFunctions.siteFormat == 'desktop' ? (
						<div className='container-fluid'>
							<HoverIntent onMouseOver={this.props.onMouseOver} onMouseOut={this.props.onMouseOut}>
								<div id='main_nav'>
									<ul className='menu'>
										{menuData.sort(this.sortCats('value')).map((menu, index) => (
											<li key={index} className={`${menu.display == 'Beauty' ? 'hide-when-international' : ''}`}>
												<Link
													to={megaMenuData ? megaMenuData[menu.display].link : this.formatURLsIfNeeded(`/c/${menu.link}`)}
													id={`topcat_${(megaMenuData ? megaMenuData[menu.display] : menu).display}`}
													className={`topCatLink`}
													manual_cm_sp={megaMenuData ? megaMenuData[menu.display].manual_cm_sp : null}
													data-supername={(megaMenuData ? megaMenuData[menu.display] : menu).display}
													onKeyDown={this.props.handleKeyPress}
													onMouseOver={this.props.handleSelectedMenuState}
													onClick={this.adaskipNavigation}
												>
													{(megaMenuData ? megaMenuData[menu.display] : menu).display}
												</Link>
												<button
													className='hiddenButtonForNav'
													data-supername={(megaMenuData ? megaMenuData[menu.display] : menu).display}
													onClick={this.props.toggleMenu}
													aria-controls='mega-menu-wrapper'
													aria-expanded='false'
													aria-label={(megaMenuData ? megaMenuData[menu.display] : menu).display}
												></button>
											</li>
										))}
									</ul>
									{this.props.getSelectedMenuState == '' ? (
										''
									) : (
										<Menu
											megaMenuState={this.props.getSelectedMenuState}
											menuHoverIntent={this.props.menuOpen}
											formatURLsIfNeeded={this.formatURLsIfNeeded}
											passedAppFunctions={this.props.passedAppFunctions}
											closeMenu={this.props.closeMenu}
										/>
									)}
								</div>
							</HoverIntent>
						</div>
					) : (
						<div className='container'>
							<div id='main_nav'>
								<ul className='menu'>
									{menuData.sort(this.sortCats('value')).map((menu, index) => (
										<li key={index} className={`${menu.display == 'Beauty' ? 'hide-when-international' : ''}`}>
											<Link
												to={megaMenuData ? megaMenuData[menu.display].link : this.formatURLsIfNeeded(`/c/${menu.link}`)}
												id={`topcat_${(megaMenuData ? megaMenuData[menu.display] : menu).display}`}
												className={`topCatLink`}
												manual_cm_sp={megaMenuData ? megaMenuData[menu.display].manual_cm_sp : null}
												data-supername={(megaMenuData ? megaMenuData[menu.display] : menu).display}
												onTouchStart={this.props.handleSelectedMenuState}
												onTouchEnd={this.props.checkIt}
												onClick={this.adaskipNavigation}
											>
												{(megaMenuData ? megaMenuData[menu.display] : menu).display}
											</Link>
										</li>
									))}
								</ul>
								{this.props.getSelectedMenuState == '' ? (
									''
								) : (
									<Menu
										megaMenuState={this.props.getSelectedMenuState}
										menuHoverIntent={this.props.menuOpen}
										formatURLsIfNeeded={this.formatURLsIfNeeded}
										passedAppFunctions={this.props.passedAppFunctions}
										closeTabletMenu={this.props.closeTabletMenu}
										closeMenu={this.props.closeMenu}
									/>
								)}
							</div>
						</div>
					)}
				</nav>
				{this.props.menuOpen && this.props.passedAppFunctions.siteFormat === 'tablet' ? (
					<div className='TabletMenu__backdrop' onClick={this.props.closeTabletMenu}></div>
				) : (
					''
				)}
			</React.Fragment>
		);
	}
}

export class Menu extends Component {
	render() {
		var columns = this.props.megaMenuState.columns;
		return (
			<section
				className={classnames('mega-menu-wrapper', {
					'mega-menu-wrapper-open': this.props.menuHoverIntent,
				})}
				data-menucat=''
			>
				{this.props.passedAppFunctions.siteFormat == 'desktop' ? (
					<div className='megaMenuCloseWrapper'>
						<button className='megaMenuClose close btn-tertiary' type='button' onClick={this.props.closeMenu}>
							<CloseXIcon />
						</button>
					</div>
				) : (
					''
				)}
				<div className='row'>
					{columns.map((element, outerCount) => (
						<div className={`${this.props.megaMenuState.columns.length > 3 ? 'col-sm-3' : 'col-sm-4'}`} key={outerCount}>
							{element.groupings.map((value) => (
								<>
									<h2 className='menuCat'>
										<Choose>
											<When test={this.props.passedAppFunctions.siteFormat == 'desktop'}>
												<Link to={this.props.formatURLsIfNeeded(value.link)} manual_cm_sp={value.manual_cm_sp} onClick={this.props.closeMenu}>
													{value.display}
												</Link>
											</When>
											<Otherwise>
												<Link to={this.props.formatURLsIfNeeded(value.link)} manual_cm_sp={value.manual_cm_sp} onClick={this.props.closeTabletMenu}>
													{value.display}
												</Link>
											</Otherwise>
										</Choose>
									</h2>
									<ul className='children' key={value.display + value.manual_cm_sp}>
										{value.items.map((item, i) => (
											<li key={i + item.display}>
												<Link
													to={this.props.formatURLsIfNeeded(item.link)}
													className={`topCatSubLink`}
													manual_cm_sp={item.manual_cm_sp}
													onClick={this.props.closeMenu}
												>
													{item.display}
												</Link>
											</li>
										))}
									</ul>
								</>
							))}
						</div>
					))}
				</div>
			</section>
		);
	}
}

export default Navigation;

/**
 * Header Container Component
 */
import React, { Component } from 'react';
import axios from 'axios';

/* Import Header Styles */
require('./header.scss');

/* Import View Components */
import { MyDillardsBar } from '../../../Views/Desktop/Header/MyDillardsBar/MyDillardsBar';
import { Search } from '../../../Views/Desktop/Header/Search/Search';
import { Navigation } from '../../../Views/Desktop/Header/Navigation/Navigation';

/* Import UI Components */
import { SetInnerHTML } from '../../../UI/SetInnerHTML/SetInnerHTML';

/*Api Request*/
import callApi from '../../../../util/apiCaller';
import { ArrowLeftIcon, ArrowRightIcon } from '../../../UI/Icons/Icons';
import TippyTop from '../../../Views/Desktop/Header/TippyTop/TippyTop';
const API_URL = '/webapp/wcs/stores/servlet/';
const API_DATA = 'storeId=301&langId=-1&catalogId=301&caller=node';

export class HeaderContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			myDillardsBoxOpened: false,
			activeTab: '1',
			megaMenuState: {},
			miniCartPopoutData: '',
			myDillardsWishlistInfo: '',
			myDillardsOrderInfo: '',
			headerClicked: null,
			menuOpen: false,
			previousCatClicked: null,
			getSelectedMenuState: '',
			slideIndex: 0,
			updateCount: 0,
			isMiniCartOpen: false,
			isPlaying: true,
			initialStatePosition: false,
			isMegaMenuListener: false,
		};

		this.handleMyDillardsBoxOver = this.handleMyDillardsBoxOver.bind(this);
		this.handleMyDillardsBoxLeave = this.handleMyDillardsBoxLeave.bind(this);
		this.handleClickOpenMiniCart = this.handleClickOpenMiniCart.bind(this);
		this.handleClickCloseMiniCart = this.handleClickCloseMiniCart.bind(this);
		this.toggle = this.toggle.bind(this);
		this.NavMegaMenuData = this.NavMegaMenuData.bind(this);
		this.handleNavMegaMenuData = this.handleNavMegaMenuData.bind(this);
		this.removeItemFromBag = this.removeItemFromBag.bind(this);
		this.moveItemToWishList = this.moveItemToWishList.bind(this);
		this.removeItemFromWishList = this.removeItemFromWishList.bind(this);
		this.moveItemToBag = this.moveItemToBag.bind(this);
		this.closeTabletMenu = this.closeTabletMenu.bind(this);
		this.handleSelectedMenuState = this.handleSelectedMenuState.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.checkIt = this.checkIt.bind(this);
		this.onMouseOver = this.onMouseOver.bind(this);
		this.onMouseOut = this.onMouseOut.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.handlePromoPausePlay = this.handlePromoPausePlay.bind(this);
		this.state.storedMyDillardsInfo = JSON.parse(JSON.stringify(this.props.myDillardsInfo));
		this.state.storedIsInternational = this.props.passedAppFunctions.isInternational();
		this.state.storedCountry = this.props.passedAppFunctions.country;
		this.state.storedCurrency = this.props.passedAppFunctions.currency;
		this.forceHeaderRender = this.forceHeaderRender.bind(this);
		this.scrollToContent = this.scrollToContent.bind(this);
		this.handleMenuScroll = this.handleMenuScroll.bind(this);
		this.animationObserver = this.animationObserver.bind(this);
		this.handleAnimationRotation = this.handleAnimationRotation.bind(this);
		this.megaMenuKeyboardNav = this.megaMenuKeyboardNav.bind(this);
		this.focusMegaMenu = this.focusMegaMenu.bind(this);
	}
	forceHeaderRender() {
		this.setState({ force: true });
	}
	componentDidMount() {
		window.forceHeaderRender = this.forceHeaderRender;
		window.addEventListener('scroll', this.handleMenuScroll);
		this.animationObserver();
		if (
			this.props.siteFormat == 'tablet' &&
			this.props.headerData.JSON.ES_Header_Promo_JSON != undefined &&
			this.props.headerData.JSON.ES_Header_Promo_JSON.length > 0
		) {
			window.addEventListener('orientationchange', this.handleAnimationRotation);
		}
	}
	componentWillUnmount() {
		var megaMenu = document.querySelector('.mega-menu-wrapper');
		window.removeEventListener('scroll', this.handleMenuScroll);
		window.removeEventListener('orientationchange', this.handleAnimationRotation);
		if (megaMenu != null) {
			megaMenu.removeEventListener('keydown', this.megaMenuKeyboardNav);
		}
	}
	shouldComponentUpdate(nextProps, nextState) {
		var returnValue = false;
		if (!_.isEqual(nextProps.myDillardsInfo, this.state.storedMyDillardsInfo)) {
			this.state.storedMyDillardsInfo = JSON.parse(JSON.stringify(this.props.myDillardsInfo));
			returnValue = true;
		}
		if (this.props.passedAppFunctions.isInternational() != this.state.storedIsInternational) {
			this.state.storedIsInternational = this.props.passedAppFunctions.isInternational();
			returnValue = true;
		}
		if (this.props.passedAppFunctions.country != this.state.storedCountry || this.props.passedAppFunctions.currency != this.state.storedCurrency) {
			this.state.storedCountry = this.props.passedAppFunctions.country;
			this.state.storedCurrency = this.props.passedAppFunctions.currency;
			returnValue = true;
		}
		if (!_.isEqual(this.state, nextState)) {
			returnValue = true;
		}
		return returnValue;
	}

	componentDidUpdate() {
		if (
			this.state.megaMenuState.superCats != undefined &&
			this.props.passedAppFunctions.siteFormat == 'desktop' &&
			this.state.menuOpen == true &&
			document.querySelector('.hiddenSelected') != null
		) {
			this.focusMegaMenu();
		}
	}

	handleMenuScroll() {
		if (this.props.passedAppFunctions.siteFormat == 'desktop') {
			const initialPosition = window.pageYOffset || document.documentElement.scrollTop;
			if (initialPosition >= 100) {
				if (!this.state.initialStatePosition) {
					this.setState({ initialStatePosition: true });
				}
			} else {
				if (this.state.initialStatePosition) {
					this.setState({ initialStatePosition: false });
				}
			}
		}
		this.setState({
			menuOpen: false,
			previousCatClicked: null,
		});
		if (this.props.passedAppFunctions.siteFormat == 'desktop' && document.activeElement !== null) {
			document.activeElement.blur();
		}
		if (this.props.passedAppFunctions.siteFormat == 'tablet') {
			var talkDeskInput;
			if (document.querySelector('.tabletPage #tdWebchat') !== null) {
				talkDeskInput = document.querySelector('.tabletPage #tdWebchat');
			}
			var emailInputTablet = document.querySelector('.tabletPage #recipientEmail');
			var textInputTablet = document.querySelector('.tabletPage #recipientText');
			if (
				document.activeElement !== null &&
				document.activeElement !== emailInputTablet &&
				document.activeElement !== textInputTablet &&
				document.activeElement !== talkDeskInput
			) {
				document.activeElement.blur();
			}
		}
	}
	handleMyDillardsBoxOver(evt) {
		if (!this.state.myDillardsBoxOpened) {
			this.fetchMiniCart();
			setTimeout(() => {
				this.setState({ myDillardsBoxOpened: true });
			}, 500);
		}
	}

	handleMyDillardsBoxLeave(evt) {
		this.setState({ myDillardsBoxOpened: false });
	}
	fetchMiniCart = () => {
		let miniCartItemCount_myDillardsInfo = this.props.myDillardsInfo.miniCartInfo.cartItemCount,
			miniCartInfo = this.state.miniCartPopoutData,
			miniCartItemCount_miniCartInfo = miniCartInfo ? this.state.miniCartPopoutData.productInfo.length : '',
			updateMiniCart = miniCartItemCount_myDillardsInfo !== miniCartItemCount_miniCartInfo ? true : false;
		if (
			!this.state.fetchMiniCartRunning &&
			updateMiniCart &&
			window.DILLARDS.country == 'US' &&
			this.props.passedAppFunctions.wasParms.isMiniCartPopoutOn == 'true'
		) {
			this.state.fetchMiniCartRunning = true;
			callApi('post', `${API_URL}MiniCartPopoutCmd`, API_DATA).then((res) => {
				this.props.passedAppFunctions.updateStateWithCookies();
				this.setState({
					miniCartPopoutData: res.data,
					fetchMiniCartRunning: false,
				});
			});
		}
	};

	handleClickOpenMiniCart(e) {
		document.body.classList.add('minicart-open');
		e.stopPropagation();
		this.setState({ isMiniCartOpen: true });
		this.fetchMiniCart();
	}
	handleClickCloseMiniCart(e) {
		e.stopPropagation();
		if (this.state.isMiniCartOpen) {
			this.setState({ isMiniCartOpen: false });
			document.body.classList.remove('minicart-open');
		}
	}
	fetchMyDillardsWishlistInfo() {
		if (!this.state.fetchMyDillardsWishlistInfoRunning && !this.state.myDillardsWishlistInfo) {
			this.state.fetchMyDillardsWishlistInfoRunning = true;
			callApi('post', `${API_URL}MyDillardsInterestItems`, API_DATA).then((res) => {
				this.state.fetchMyDillardsWishlistInfoRunning = false;
				this.setState({ myDillardsWishlistInfo: res.data });
			});
		}
	}
	fetchMyDillardsOrderInfo() {
		if (!this.state.fetchMyDillardsOrderInfoRunning && !this.state.myDillardsOrderInfo) {
			this.state.fetchMyDillardsOrderInfoRunning = true;
			callApi('post', `${API_URL}MyDillardsOrderHistoryList`, API_DATA).then((res) => {
				this.state.fetchMyDillardsOrderInfoRunning = false;
				this.setState({ myDillardsOrderInfo: res.data });
			});
		}
	}
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.fetchMyDillardsWishlistInfo();
			this.fetchMyDillardsOrderInfo();
			this.fetchMiniCart();
			this.setState({ activeTab: tab });
		}
	}

	NavMegaMenuData() {
		var axiosConfig = {
			method: 'get',
			url: '/html/dropdown.json?v=' + this.props.headerData.JSON.wasParms.cacheCallVariable,
		};
		axios(axiosConfig).then((response) => {
			var returnData = response.data;
			if (typeof returnData == 'string') {
				returnData = returnData.replace(/[\u0000-\u001f]/g, '');
				returnData = JSON.parse(returnData);
			}
			this.setState({ megaMenuState: returnData });
		});
	}

	handleNavMegaMenuData(evt) {
		setTimeout(() => {
			this.NavMegaMenuData();
		}, 500);
	}

	moveItemToWishList(event) {
		var index = event.currentTarget.getAttribute('data-index');
		var attributes = event.currentTarget.getAttribute('data-attributes').split('#double;').join('"').split('#back;').join('\\');
		var catEntry = event.currentTarget.getAttribute('data-catentryid');
		var item = event.currentTarget.getAttribute('data-orderitemid');
		var orderId = '.';

		var ajaxUrl = '/webapp/wcs/stores/servlet/InterestItemAdd?';

		ajaxUrl +=
			'orderItemId=' +
			item +
			'&orderId=' +
			orderId +
			'&updatePrices=1&storeId=301&catalogId=301&langId=-1&calculationUsageId=-1&productId=' +
			catEntry +
			'&field3=' +
			attributes +
			'&catEntryId=' +
			catEntry +
			'&errorViewName=InterestItemDisplay&wlFromAjaxBag=true';
		ajaxUrl += '&URL=InterestItemAdd%3fURL%3dInterestItemDisplay';
		ajaxUrl +=
			'&rURL=InterestItemAdd%3FwlFromAjaxBag%3Dtrue%26orderId%3d' +
			orderId +
			'%26field3%3d' +
			attributes +
			'%26orderItemId%3d' +
			item +
			'%26langId%3d-1%26storeId%3d301%26catalogId%3d301%26catEntryId%3d' +
			catEntry +
			'%26productId%3d' +
			catEntry +
			'%26URL%3dInterestItemDisplay';

		$.ajax({
			type: 'POST',
			url: ajaxUrl,
			success: (data, textStatus) => {
				if (data.indexOf('Verify Your Password') >= 0) {
					window.location = ajaxUrl;
				} else if (data.indexOf('You have reached the limit of items in your wishlist') >= 0) {
					this.setState({ shoppingBagMessage: 'You have reached the limit of items in your wishlist' });
				} else {
					this.state.miniCartPopoutData = false;
					this.state.myDillardsWishlistInfo = false;
					this.fetchMiniCart();
				}
				return false;
			},
			error: (jqXHR, textStatus, errorThrown) => {
				var ajaxUrl2 = '/webapp/wcs/stores/servlet/InterestItemAdd?';

				ajaxUrl2 +=
					'orderItemId=' +
					item +
					'&orderId=' +
					orderId +
					'&updatePrices=1&storeId=301&catalogId=301&langId=-1&calculationUsageId=-1&productId=' +
					catEntry +
					'&field3=' +
					attributes +
					'&catEntryId=' +
					catEntry +
					'&errorViewName=3DInterestItemDisplay&wlFromAjaxBag=true';
				ajaxUrl2 += '&URL=InterestItemAdd%3fURL%3dInterestItemDisplay';
				ajaxUrl2 +=
					'&rURL=InterestItemAdd%3FwlFromAjaxBag%3Dtrue%26orderId%3d' +
					orderId +
					'%26field3%3d' +
					attributes +
					'%26orderItemId%3d' +
					item +
					'%26langId%3d-1%26storeId%3d301%26catalogId%3d301%26catEntryId%3d' +
					catEntry +
					'%26productId%3d' +
					catEntry +
					'%26URL%3dInterestItemDisplay';
				window.location = ajaxUrl2;
			},
		});
	}
	removeItemFromBag(event) {
		var index = event.currentTarget.getAttribute('data-index');
		var catEntry = event.currentTarget.getAttribute('data-catentryid');
		var item = event.currentTarget.getAttribute('data-orderitemid');
		var orderId = '.';

		var ajaxUrl = '/webapp/wcs/stores/servlet/OrderItemDelete?';

		ajaxUrl +=
			'orderItemId=' +
			item +
			'&orderId=' +
			orderId +
			'&updatePrices=1&storeId=301&catalogId=301&langId=-1&calculationUsageId=-1&productId=' +
			catEntry +
			'&errorViewName=OrderItemDisplayView';
		ajaxUrl += '&URL=OrderCalculate%3FURL%3DOrderItemDisplay%26location%3DMyDillards';

		$.ajax({
			type: 'POST',
			url: ajaxUrl,
			dataType: 'json',
			success: (data, textStatus) => {
				this.state.miniCartPopoutData = false;
				this.fetchMiniCart();
			},
			error: (jqXHR, textStatus, errorThrown) => {
				var ajaxUrl2 = '/webapp/wcs/stores/servlet/OrderItemDelete?';

				ajaxUrl2 +=
					'orderItemId=' +
					item +
					'&orderId=' +
					orderId +
					'&updatePrices=1&storeId=301&catalogId=301&langId=-1&calculationUsageId=-1&productId=' +
					catEntry +
					'&errorViewName=OrderItemDisplayView';
				ajaxUrl2 += '&URL=OrderCalculate%3FURL%3DOrderItemDisplay';
				window.location = ajaxUrl2;
				return false;
			},
		});
	}
	moveItemToBag(event) {
		var index = event.target.getAttribute('data-index');
		var attribute = event.target.getAttribute('data-attributes').split('#double;').join('"').split('#back;').join('\\');
		var item = event.target.getAttribute('data-catentryid');

		var ajaxUrl = '/webapp/wcs/stores/servlet/InterestItemDelete?';

		ajaxUrl +=
			'catEntryId=' +
			item +
			'&storeId=301&catalogId=301&langId=-1&calculationUsageId=-1&calculationUsageId=-2&updatePrices=1&quantity=1&errorViewName=InterestItemDisplayView';
		ajaxUrl +=
			'&URL=OrderItemAdd%3FURL%3DOrderCalculate%3FURL%3DOrderItemDisplay%26location%3DMyDillards%26cm_vc%3Dwishlist%26myDillardsBar%3Dtrue%26catEntryId%3D' +
			item +
			'&field2=' +
			attribute;

		$.ajax({
			type: 'POST',
			url: ajaxUrl,
			dataType: 'json',
			success: (data, textStatus) => {
				this.state.myDillardsWishlistInfo = false;
				this.state.miniCartPopoutData = false;
				this.fetchMyDillardsWishlistInfo();
				this.props.passedAppFunctions.updateStateWithCookies();
				return false;
			},
			error: (jqXHR, textStatus, errorThrown) => {
				var ajaxUrl2 = '/webapp/wcs/stores/servlet/InterestItemDelete?';

				ajaxUrl2 +=
					'catEntryId=' +
					item +
					'&storeId=301&catalogId=301&langId=-1&calculationUsageId=-1&calculationUsageId=-2&updatePrices=1&quantity=1&errorViewName=InterestItemDisplayView';
				ajaxUrl2 += '&URL=OrderItemAdd%3FURL%3DOrderCalculate%3FURL%3DOrderItemDisplay%26cm_vc%3Dwishlist%26catEntryId%3D' + item;
				window.location = ajaxUrl2;

				return false;
			},
		});
	}
	removeItemFromWishList(event) {
		var index = event.target.getAttribute('data-index');
		var item = event.target.getAttribute('data-catentryid');

		var ajaxUrl = '/webapp/wcs/stores/servlet/InterestItemDelete?';

		ajaxUrl += 'catEntryId=' + item + '&listId=.&storeId=301&catalogId=301&langId=-1&location=RemoveOnly&URL=InterestItemDisplay';

		$.ajax({
			type: 'POST',
			url: ajaxUrl,
			dataType: 'json',
			success: (data, textStatus) => {
				this.state.myDillardsWishlistInfo = false;
				this.fetchMyDillardsWishlistInfo();
				return false;
			},
			error: (jqXHR, textStatus, errorThrown) => {
				var ajaxUrl2 = '/webapp/wcs/stores/servlet/InterestItemDelete?';

				ajaxUrl2 += 'catEntryId=' + item + '&listId=.&storeId=301&catalogId=301&langId=-1&URL=InterestItemDisplay';
				window.location = ajaxUrl2;

				return false;
			},
		});
	}
	myDillardsBarFunctions() {
		return {
			moveItemToWishList: this.moveItemToWishList,
			removeItemFromBag: this.removeItemFromBag,
			moveItemToBag: this.moveItemToBag,
			removeItemFromWishList: this.removeItemFromWishList,
			shoppingBagMessage: this.state.shoppingBagMessage,
		};
	}
	closeTabletMenu(event) {
		this.setState({
			menuOpen: false,
			previousCatClicked: null,
		});
	}
	closeMenu() {
		this.setState({
			menuOpen: false,
			previousCatClicked: null,
		});
	}

	onMouseOver() {
		this.props.passedAppFunctions.siteFormat == 'desktop' ? this.setState({ menuOpen: true }) : '';
	}
	onMouseOut() {
		this.props.passedAppFunctions.siteFormat == 'desktop' ? this.setState({ menuOpen: false }) : '';
	}

	// www 16607 ADA keyboard navigation
	toggleMenu(event) {
		var changed = false;
		var target = event.target.dataset.supername;
		var hiddenButtonForNav = document.querySelectorAll('.hiddenButtonForNav');

		for (var i = 0; i < hiddenButtonForNav.length; i++) {
			hiddenButtonForNav[i].classList.remove('hiddenSelected');
			hiddenButtonForNav[i].setAttribute('aria-expanded', 'false');
		}
		if (target != false && this.state.megaMenuState.superCats && this.state.getSelectedMenuState != this.state.megaMenuState.superCats[target]) {
			this.setState({
				getSelectedMenuState: this.state.megaMenuState.superCats[target],
			});
			changed = true;
		}
		if (!changed || !this.state.menuOpen) {
			this.props.passedAppFunctions.siteFormat == 'desktop' ? this.setState({ menuOpen: !this.state.menuOpen }) : '';
		}
		event.target.classList.add('hiddenSelected');
		event.target.ariaExpanded = 'true';
	}

	focusMegaMenu() {
		var menuCloseButton = document.querySelector('.megaMenuClose');
		var megaMenu = document.querySelector('.mega-menu-wrapper');

		if (this.state.isMegaMenuListener == false) {
			megaMenu.addEventListener('keydown', this.megaMenuKeyboardNav);
			this.setState({ isMegaMenuListener: true });
		}
		menuCloseButton.style.visibility = 'visible';
		menuCloseButton.focus();
	}

	megaMenuKeyboardNav(e) {
		var focusableElements = document.querySelectorAll('.mega-menu-wrapper-open a');
		var lastFocusable = focusableElements[focusableElements.length - 1];
		var currentHiddenNav = document.querySelector('.hiddenSelected');
		var menuCloseButton = document.querySelector('.megaMenuClose');

		if (e.key === 'Enter' && document.activeElement == menuCloseButton) {
			currentHiddenNav.focus();
			currentHiddenNav.classList.remove('hiddenSelected');
			currentHiddenNav.setAttribute('aria-expanded', 'false');
			menuCloseButton.style.visibility = 'hidden';
			this.setState({ menuOpen: false });
			e.preventDefault();
		}
		if (e.key === 'Escape') {
			currentHiddenNav.focus();
			currentHiddenNav.classList.remove('hiddenSelected');
			currentHiddenNav.setAttribute('aria-expanded', 'false');
			menuCloseButton.style.visibility = 'hidden';
			this.setState({ menuOpen: false });
		}
		if (e.shiftKey && e.keyCode == 9 && document.activeElement == menuCloseButton) {
			currentHiddenNav.focus();
			currentHiddenNav.classList.remove('hiddenSelected');
			currentHiddenNav.setAttribute('aria-expanded', 'false');
			menuCloseButton.style.visibility = 'hidden';
			this.setState({ menuOpen: false });
			e.preventDefault();
		}
		if (e.key === 'Tab' && document.activeElement == lastFocusable) {
			menuCloseButton.focus();
			e.preventDefault();
		}
	}
	// www 16607 ADA keyboard navigation

	handleSelectedMenuState(event) {
		var target = event.target.dataset.supername;
		var hiddenButtonForNav = document.querySelectorAll('.hiddenButtonForNav');
		var menuCloseButton = document.querySelector('.megaMenuClose');
		for (var i = 0; i < hiddenButtonForNav.length; i++) {
			hiddenButtonForNav[i].classList.remove('hiddenSelected');
			hiddenButtonForNav[i].setAttribute('aria-expanded', 'false');
		}
		if (menuCloseButton != null) {
			menuCloseButton.style.visibility = 'hidden';
		}
		if (target != false && this.state.megaMenuState.superCats)
			this.setState({
				getSelectedMenuState: this.state.megaMenuState.superCats[target],
			});
	}

	handleKeyPress(event) {
		if (event.key == 'Enter') {
			event.preventDefault();
			this.handleSelectedMenuState(event);
			this.onMouseOver;
		} else this.onMouseOut;
	}
	checkIt(event) {
		var target = event.target.dataset.supername;
		if (this.props.passedAppFunctions.siteFormat == 'tablet') {
			this.setState({ previousCatClicked: target });
			$(event.target).removeClass('noHover');
			if (target == this.state.previousCatClicked) {
				//CLOSE MENU AFTER DOUBLE CLICK
				this.closeTabletMenu();
				this.setState({ previousCatClicked: null });
				$(event.target).addClass('noHover');
			} else {
				event.preventDefault(event);
				$(event.target).addClass('topCatHover');
				$('.mega-menu-wrapper').attr('data-menucat', `${event.target.text}`);
				this.setState({ menuOpen: true });
			}
		}
		//11673 Supercategory Dropdown showing background cursor
		$('input').trigger('blur');
	}

	scrollToContent() {
		if (this.props.passedAppFunctions.siteFormat == 'desktop') {
			$('html, body').animate({ scrollTop: $('#content_wrapper').offset().top - 70 }, 50);
		}
	}

	animationObserver() {
		if (this.props.headerData.JSON.ES_Header_Promo_JSON != undefined) {
			var rootElement = document.getElementById('headerPromo');
			if (window.IntersectionObserver) {
				if (animationObserver) {
					animationObserver.disconnect();
				}
				var options = {
					root: rootElement,
					rootMargin: '0px',
					threshold: 0.5,
				};

				var animationObserver = new IntersectionObserver((entries, observer) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							entry.target.ariaHidden = 'false';
							entry.target.firstElementChild.firstElementChild.setAttribute('tabindex', '0');
						} else {
							entry.target.ariaHidden = 'true';
							entry.target.firstElementChild.firstElementChild.setAttribute('tabindex', '-1');
						}
					});
				}, options);

				document.querySelectorAll('#animatedPromo .promoWrapper').forEach((promoSlide) => {
					animationObserver.observe(promoSlide);
				});
			}
		}
	}

	// fix for iOS tablet edge cases - animation skipping on rotation
	handleAnimationRotation() {
		var animatedPromo = document.getElementById('animatedPromo');
		animatedPromo.setAttribute('style', 'animation-play-state: paused!important');
		setTimeout(function () {
			animatedPromo.removeAttribute('style');
		}, 2000);
	}

	handlePromoPausePlay() {
		var animatedPromo = document.getElementById('animatedPromo');
		if (this.state.isPlaying) {
			this.setState({ isPlaying: false });
			animatedPromo.style.animationPlayState = 'paused';
		} else {
			this.setState({ isPlaying: true });
			animatedPromo.style.animationPlayState = 'running';
		}
	}

	render() {
		try {
			if (typeof window != 'undefined') {
				var theUserAgent = navigator.userAgent;
				var userAgentsWeAreLookingFor = [
					'Googlebot',
					'Chrome-Lighthouse',
					'PTST',
					'AdsBot-Google',
					'DuckDuckBot',
					'Slurp',
					'Baiduspider',
					'YandexBot',
					'Facebot',
					'facebookexternalhit',
					'ia_archiver',
					'LinkedInBot',
					'Google Page Speed Insights',
					'Search Console',
					'Sogou web spider',
					'bingbot',
					'Twitterbot',
					'YandexMobileBot',
					'Pingdom.com_bot',
					'SpeedCurve',
				];
				for (var iUA = 0; iUA < userAgentsWeAreLookingFor.length; iUA++) {
					if (theUserAgent.indexOf(userAgentsWeAreLookingFor[iUA]) > -1) {
						//this is a user agent we want not to autoplay
						settings.autoplay = false;
						break;
					}
				}
			}
		} catch (e) {
			// console.log(e);
		}

		var promoCount;
		if (this.props.headerData.JSON.ES_Header_Promo_JSON != undefined) {
			promoCount = this.props.headerData.JSON.ES_Header_Promo_JSON.length;
		}

		var headerPromos = [];
		if (this.props.headerData.JSON.ES_Header_Promo_JSON && Array.isArray(this.props.headerData.JSON.ES_Header_Promo_JSON)) {
			headerPromos = this.props.headerData.JSON.ES_Header_Promo_JSON;
		}

		if (typeof window != 'undefined' && window.injectHeaderPromos) {
			try {
				headerPromos = window.injectHeaderPromos(headerPromos);
			} catch (e) {
				console.log(e);
			}
		}

		return (
			<React.Fragment>
				<TippyTop closeMenu={this.closeMenu} />
				<header>
					{this.props.headerData.JSON.ES_Tippy_Top_HTML !== undefined && this.props.headerData.JSON.ES_Tippy_Top_HTML != '&nbsp;' ? (
						<div className='header_promo_static_wrapper' role='region' aria-label='Promotional Static'>
							<div className='promoStaticWrapper' aria-hidden='false'>
								<div className='promoStaticHeading'>
									<SetInnerHTML innerHTML={this.props.headerData.JSON.ES_Tippy_Top_HTML} espotname='ES_Tippy_Top_HTML' />
								</div>
							</div>
						</div>
					) : (
						''
					)}
					<div id='headerPromo' className='header_promo' tabIndex='-1'>
						{this.props.passedAppFunctions.siteFormat == 'desktop' ? (
							<a id='skipNavLink' className='skipNavigation' href='#content_wrapper' title='Skip to main content' onClick={this.scrollToContent}>
								Skip to main content
							</a>
						) : (
							''
						)}

						<div className='header_promo_wrapper' onTouchEnd={this.closeMenu} role='region' aria-label='Promotional Carousel'>
							<div id='animatedPromo' className={`promoCount-${promoCount}`}>
								{headerPromos.map((attr, index) => (
									<React.Fragment key={index}>
										<SetInnerHTML className='promoWrapper' innerHTML={index > 0 ? attr.split('tabindex').join('asdindex') : attr} />
									</React.Fragment>
								))}
							</div>
							{this.props.passedAppFunctions.siteFormat == 'desktop' ? (
								<button className='button' onClick={this.handlePromoPausePlay}>
									<Choose>
										<When test={this.state.isPlaying}>
											<svg
												className='pauseButtonTippy'
												role='img'
												aria-labelledby='pauseButton'
												aria-hidden='true'
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 45 47'
												width='15'
											>
												<title id='pauseButton'>pause promo</title>
												<g data-name='Layer 2'>
													<g data-name='Layer 1'>
														<rect width='17' height='47' rx='2.2' />
														<rect x='28' width='17' height='47' rx='2.2' />
													</g>
												</g>
											</svg>
											Pause
										</When>
										<Otherwise>
											<svg
												className='playButtonTippy'
												role='img'
												aria-labelledby='playButton'
												aria-hidden='true'
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 42.43 48.31'
												width='15'
											>
												<title id='playButton'>play promo</title>
												<g data-name='Layer 2'>
													<g data-name='Layer 1'>
														<path d='M2.63.46,42,23.06a1.54,1.54,0,0,1,0,2.19L2.63,47.86A1.54,1.54,0,0,1,0,46.76V1.55A1.54,1.54,0,0,1,2.63.46Z' />
													</g>
												</g>
											</svg>
											Play
										</Otherwise>
									</Choose>
								</button>
							) : (
								''
							)}
						</div>
						<span className='headerStoreLocatorLink hide-when-international'>
							<svg className='storeMapMarker' height='15' width='15' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.69 48.8'>
								<title>map-marker</title>
								<g id='Layer_2' data-name='Layer 2'>
									<g id='Layer_1-2' data-name='Layer 1'>
										<path d='M15.35,0A15.35,15.35,0,0,0,0,15.35C0,23.82,15.35,48.8,15.35,48.8s15.35-25,15.35-33.46A15.35,15.35,0,0,0,15.35,0Zm0,23.92A7.92,7.92,0,1,1,23.27,16,7.92,7.92,0,0,1,15.35,23.92Z' />
									</g>
								</g>
							</svg>
							<a href='/stores' manual_cm_sp='headerPromo-_-storeLocator'>
								Store Locations
							</a>
						</span>
					</div>

					<MyDillardsBar
						handleMyDillardsBoxOver={this.handleMyDillardsBoxOver}
						handleMyDillardsBoxLeave={this.handleMyDillardsBoxLeave}
						myDillardsBoxOpened={this.state.myDillardsBoxOpened}
						toggle={this.toggle}
						activeTab={this.state.activeTab}
						myDillardsInfo={this.props.myDillardsInfo}
						handleClickOpenMiniCart={this.handleClickOpenMiniCart}
						handleClickCloseMiniCart={this.handleClickCloseMiniCart}
						isMiniCartOpen={this.state.isMiniCartOpen}
						fetchMiniCart={this.fetchMiniCart}
						miniCartPopoutData={this.state.miniCartPopoutData}
						myDillardsWishlistInfo={this.state.myDillardsWishlistInfo}
						myDillardsOrderInfo={this.state.myDillardsOrderInfo}
						myDillardsBarFunctions={this.myDillardsBarFunctions()}
						domainName={this.props.passedAppFunctions.wasParms.domainName}
						passedAppFunctions={this.props.passedAppFunctions}
						siteFormat={this.props.siteFormat}
						headerData={this.props.headerData}
						closeMenu={this.closeMenu}
					/>

					<section id='header_desktop_wrapper' className='header_desktop_wrapper_promo' onTouchEnd={this.closeMenu}>
						<div className={`${this.props.passedAppFunctions.siteFormat == 'tablet' ? 'container' : 'container-fluid'}`}>
							<div id='header' onClick={this.closeTabletMenu} className={`row`}>
								<div className={`col-sm-2`}>
									<SetInnerHTML innerHTML={this.props.headerData.JSON.ES_Logo} espotname='ES_Logo' />
								</div>

								<div className={`col-sm-10`}>
									<Search passedAppFunctions={this.props.passedAppFunctions} />
								</div>
							</div>
						</div>
					</section>
				</header>
				<Navigation
					passedAppFunctions={this.props.passedAppFunctions}
					NavMegaMenuData={this.NavMegaMenuData}
					megaMenuState={this.state.megaMenuState}
					handleNavMegaMenuData={this.handleNavMegaMenuData}
					checkIt={this.checkIt}
					menuOpen={this.state.menuOpen}
					onMouseOut={this.onMouseOut}
					toggleMenu={this.toggleMenu}
					onMouseOver={this.onMouseOver}
					getSelectedMenuState={this.state.getSelectedMenuState}
					handleSelectedMenuState={this.handleSelectedMenuState}
					closeTabletMenu={this.closeTabletMenu}
					closeMenu={this.closeMenu}
				/>
			</React.Fragment>
		);
	}
}

function PromoArrowLeftIcon(props) {
	const { onClick } = props;
	return (
		<button className='icon i-arrow-up' onClick={onClick}>
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.77 25.39'>
				<title>arrow-up</title>
				<g id='Layer_2' data-name='Layer 2'>
					<g id='Layer_1-2' data-name='Layer 1'>
						<path d='M47.77,25.39a1,1,0,0,1-.71-.29L24.39,2.41,1.71,25.09A1,1,0,0,1,.29,23.68L23.68.29a1,1,0,0,1,1.41,0L48.48,23.68a1,1,0,0,1-.71,1.71Z' />
					</g>
				</g>
			</svg>
		</button>
	);
}

function PromoArrowRightIcon(props) {
	const { onClick } = props;
	return (
		<button className='icon i-arrow-down' onClick={onClick}>
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.77 25.39'>
				<title>arrow-down</title>
				<g id='Layer_2' data-name='Layer 2'>
					<g id='Layer_1-2' data-name='Layer 1'>
						<path d='M24.39,25.39a1,1,0,0,1-.71-.29L.29,1.71A1,1,0,0,1,1.71.29L24.39,23,47.07.29a1,1,0,0,1,1.41,1.41L25.09,25.09A1,1,0,0,1,24.39,25.39Z' />
					</g>
				</g>
			</svg>
		</button>
	);
}

export default HeaderContainer;

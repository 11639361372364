import React, { Component } from 'react';

import HeaderContainer from '../HeaderContainer/HeaderContainer';
import ContentContainer from '../ContentContainer/ContentContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import FooterContainerTablet from '../FooterContainer/FooterContainerTablet';
import SharedApp from '../../Shared/SharedApp/SharedApp';
import { Helmet } from 'react-helmet';
import { HeaderError } from '../../../Error/Desktop/HeaderError';
import { ContentError } from '../../../Error/Shared/ContentError';
import { FooterError } from '../../../Error/Desktop/FooterError';

require('../../../../styles/desktop/main.scss');

import loadable from '@loadable/component';
const ProductDetail = loadable(() => import(/* webpackChunkName: "desktopProductDetail" */ '../../../Views/Desktop/Content/ProductDetail/ProductDetail'));
const ProductDisplayEcard = loadable(() =>
	import(/* webpackChunkName: "desktopProductDisplayEcard" */ '../../../Views/Desktop/Content/ProductDetail/ProductDisplayEcard/ProductDisplayEcard')
);
const Layouts = loadable(() => import(/* webpackChunkName: "desktopLayouts" */ '../../../Views/Desktop/Content/Layouts/Layouts'));

// const HeaderContainer = loadable(() => import(/* webpackChunkName: "desktopHeader" */ '../HeaderContainer/HeaderContainer'));
// const FooterContainer = loadable(() => import(/* webpackChunkName: "desktopFooter" */ '../FooterContainer/FooterContainer'));

const StoreDetail = loadable(() => import(/* webpackChunkName: "desktopStoreDetail" */ '../../../Views/Desktop/Content/StoreDetail/StoreDetail'));
const StoreHome = loadable(() => import(/* webpackChunkName: "desktopStoreHome" */ '../../../Views/Desktop/Content/StoreHome/StoreHome'));
const ChangeCountry = loadable(() => import(/* webpackChunkName: "desktopChangeCountry" */ '../../../Views/Shared/Content/ChangeCountry/ChangeCountry'));
const ContactUs = loadable(() => import(/* webpackChunkName: "desktopContactUs" */ '../../../Views/Shared/Content/ContactUs/ContactUs'));
const StorePurchaseReturns = loadable(() => import('../../../Views/Shared/Content/StorePurchaseReturns/StorePurchaseReturns'));
const Subscriptions = loadable(() => import('../../../Views/Shared/Content/Member/Subscriptions'));

export class DesktopApp extends SharedApp {
	constructor (props) {
		super(props);
	}

	passedAppFunctionsWithChunks () {
		var superPassedAppFunctions = super.passedAppFunctions();
		superPassedAppFunctions.chunks = {
			Layouts: Layouts,
			ProductDetail: ProductDetail,
			ProductDisplayEcard: ProductDisplayEcard,
			StoreDetail: StoreDetail,
			StoreHome: StoreHome,
			ChangeCountry: ChangeCountry,
			ContactUs: ContactUs,
			StorePurchaseReturns: StorePurchaseReturns,
			Subscriptions: Subscriptions,
		};
		return superPassedAppFunctions;
	}
	render () {
		return (
			<div id='app_wrapper' className={`${this.props.initialState.siteFormat}Page`}>
				{this.state.contentData.productLayout == 'NotAvailable' ? <Helmet meta={[{ name: 'robots', content: 'noindex' }]} /> : ''}
				{this.state.contentData.productLayout == 'NotAvailable' ? (
					<Helmet
						meta={[{ charset: 'utf-8' }]}
						title='Dillards.com'
						link={[{ rel: 'canonical', href: 'https://www.dillards.com' + this.state.contentData.productURL }]}
					/>
				) : (
					<Helmet meta={[{ charset: 'utf-8' }]} title='Dillards.com' link={[{ rel: 'canonical', href: 'https://www.dillards.com' }]} />
				)}

				{/* <Helmet>
              <meta charSet="utf-8" />
              <title>Dillards.com</title>
              <link rel="canonical" href="https://www.dillards.com" />
            </Helmet> */}
				{this.state.country != 'US' ? (
					<style type='text/css'>{`
                  .hide-when-international {
                    display:none!important;
                  }
                  .show-when-international {
                    display:block;
                  }
                `}</style>
				) : (
					<style type='text/css'>{`
                  .show-when-international {
                    display:none!important;
                  }
                `}</style>
				)}
				<HeaderError passedAppFunctions={this.passedAppFunctions()} contentData={this.state.contentData}>
					<HeaderContainer
						headerData={this.state.headerFooterData}
						myDillardsInfo={this.state.myDillardsInfo}
						passedAppFunctions={this.passedAppFunctions()}
						siteFormat={this.props.initialState.siteFormat}
					/>
				</HeaderError>
				<ContentError passedAppFunctions={this.passedAppFunctions()} contentData={this.state.contentData} quickViewData={this.state.quickViewData}>
					<ContentContainer
						contentData={this.state.contentData}
						quickViewData={this.state.quickViewData}
						passedAppFunctions={this.passedAppFunctionsWithChunks()}
					/>
				</ContentError>
				<FooterError passedAppFunctions={this.passedAppFunctions()} contentData={this.state.contentData}>
					{this.state.siteFormat == 'tablet' ? (
						<FooterContainerTablet footerData={this.state.headerFooterData} passedAppFunctions={this.passedAppFunctions()} />
					) : (
						<FooterContainer footerData={this.state.headerFooterData} passedAppFunctions={this.passedAppFunctions()} />
					)}
				</FooterError>
			</div>
		);
	}
	componentDidMount () {
		if (navigator.userAgent.match(/(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/)) {
			$('html').addClass('internet-explorer');
		} else if (/Edge/.test(navigator.userAgent)) $('html').addClass('microsoft-edge');
		else if (navigator.userAgent.indexOf('Firefox') > -1) $('html').addClass('firefox');
		this.bindPreloadRequiredChunk(['/p/:name/:productId', '/p/:name/:productId/outfit', '/p/:name/:productId/sale'].sort().join('|'), ProductDetail);
		this.bindPreloadRequiredChunk(
			[
				'/brand/:manufacturer',
				'/brand/:manufacturer/:categoryName',
				'/brand/:manufacturer/:categoryName/:pathModifier',
				'/c/:pathToken',
				'/c/:pathToken/:pathModifier',
				'/c/:pathToken/:pathModifier/:color',
				'/search-term/:searchTerm',
				'/search-term/:searchTerm/:categoryName',
				'/search-term/:searchTerm/:categoryName/:pathModifier',
				'/chanel/c/:pathToken',
				'/',
			]
				.sort()
				.join('|'),
			Layouts
		);
		this.bindPreloadRequiredChunk('ecard', ProductDisplayEcard);

		this.bindPreloadRequiredChunk('/changecountry', ChangeCountry);
		this.bindPreloadRequiredChunk('/contactus', ContactUs);
		this.bindPreloadRequiredChunk('/returns', StorePurchaseReturns);
		this.bindPreloadRequiredChunk('/member/subscriptions', Subscriptions);
		this.bindPreloadRequiredChunk('/stores/:name/:storeNum', StoreDetail);
		this.bindPreloadRequiredChunk(['/stores', '/stores/:stateName'].sort().join('|'), StoreHome);

		//new work
		super.componentDidMount();
	}
}

export default DesktopApp;

import React from 'react';
import { SharedError } from '../Shared/SharedError';
import { Button, Form, FormGroup, Input } from 'reactstrap';

export class HeaderError extends SharedError {
    constructor(props) {
        super(props);
        this.stripSearchCharacters = this.stripSearchCharacters.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    stripSearchCharacters(term){
		var result = '';
		var pattern = "[\\w\\d\\!\\@\\_\\*\\(\\)\\-\\:\\.\\,\\[\\]\\# \\/\\'\\" + String.fromCharCode(201) + "\\" + String.fromCharCode(232) + "\\" + String.fromCharCode(233) + "\\" + String.fromCharCode(234) + "\\" + String.fromCharCode(244)+ "\\" + String.fromCharCode(216) + "\\" + String.fromCharCode(248)+  "]";
		for (var x = 0; x < term.length; x++) {
            var currentChar = term.charAt(x);
            if (currentChar.search(pattern) > -1) result += currentChar;
		}
		return result;
    }
    handleChange(event) {
		var newSearchTerm = this.stripSearchCharacters(event.target.value).split('  ').join(' ');
		var oldSearchTerm = this.state.searchTerm;
		var skipNewSuggestions = false;
		if(newSearchTerm != oldSearchTerm && newSearchTerm.length <= 60){
			this.setState({searchTerm:newSearchTerm});
			if(newSearchTerm.trim() == oldSearchTerm) skipNewSuggestions = true;
        }
    }
    handleSubmit(event) {
		if(!this.state.searchTerm || this.state.searchTerm.trim().length < 3) event.preventDefault();
	}
    menuData(){
        //link and display
        var returnArray = [];
        returnArray.push({"display":"Women","link":"women"});
        returnArray.push({"display":"Juniors","link":"juniors"});
        returnArray.push({"display":"Shoes","link":"shoes"});
        returnArray.push({"display":"Handbags","link":"handbags"});
        returnArray.push({"display":"Accessories","link":"accessories"});
        returnArray.push({"display":"Lingerie","link":"lingerie"});
        returnArray.push({"display":"Beauty","link":"beauty"});
        returnArray.push({"display":"Men","link":"men"});
        returnArray.push({"display":"Kids","link":"kids"});
        returnArray.push({"display":"Home","link":"home"});
        return returnArray;
    }
    render() {
        if (this.state.error) {
            return (
                <div>
                    <section id='header_desktop_wrapper'>
                    <div id='my_dillards_bar_wrapper'>
                    <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <ul>
                                        <li className='hide-when-international' >
                                            <a href='/webapp/wcs/stores/servlet/LogonForm?storeId=301&langId=-1&catalogId=301'>Login / My Account</a>
                                        </li>
                                        <li className='hide-when-international' >
                                            <a href='/webapp/wcs/stores/servlet/InterestItemDisplay?storeId=301&catalogId=301&langId=-1'>My Wish List</a>
                                        </li>
                                        <li >
                                            <a href='/webapp/wcs/stores/servlet/OrderItemDisplay?storeId=301&catalogId=301&langId=-1' >Shopping Bag</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid'>
                            <header id='header'>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <a href='/' manual_cm_sp='Header-_-Logo-_-ImageLink' id='logo-espot'>
                                            <img src='/images/StyleOfYourLifeLogo.png' />
                                        </a>
                                    </div>
                                    <div className='col-sm-4'></div>
                                    <div className='col-sm-5'>
                                        <div id="quick_links">
                                            <div>
                                                <ul>
                                                    <li>
                                                        <a href="/c/sale-clearance" manual_cm_sp="Header-_-SaleClearance-_-TextLink" className="sale-clearance">Sale and Clearance</a>
                                                    </li>
                                                    <li>
                                                        <a href="/c/shopbybrand" manual_cm_sp="Header-_-ShopByBrand-_-TextLink">Shop By Brand</a>
                                                    </li>
                                                    <li>
                                                        <a href="/c/limited-availability" manual_cm_sp="Header-_-LimitedAvailability-_-TextLink" style={{"color":"#267cb5"}}>Limited Availability</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='search-area'>
                                                <Form inline onSubmit={this.handleSubmit} action={`/search-term/${encodeURIComponent(this.state.searchTerm)}`} method='GET' >
                                                    <FormGroup>
                                                        <Input type='text' value={this.state.searchTerm || ''} onChange={this.handleChange} className='form-control search-input search-enabled' />
                                                        <Button data-twist='search-button' className='btn btn-search' title='Search Dillards.com' type='submit'></Button>
                                                        {typeof window != 'undefined' && window.DILLARDS.country != 'US' ? <Input type='hidden' name='facet' value='dil_shipinternational:Y' /> : ''}
                                                    </FormGroup>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </section>
                    <section id="menu_wrapper">
                      <div className={`${this.props.passedAppFunctions.siteFormat == 'tablet' ? 'container' : 'container-fluid'}`}>
                            <nav id="main_nav">
                                <ul className="menu">
                                    {this.menuData().map((menu, index) =>
                                        <li key={index}>
                                            <a href={`/c/${menu.link}`} id={`topcat_${menu.display}`} data-supername={menu.display}>
                                                {menu.display}
                                            </a>
                                        </li>)}
                                </ul>
                            </nav>
                        </div>
                    </section>
                    {this.renderHiddenError('Header')}
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

export default HeaderError;

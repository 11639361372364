/**
 * Content Container Component
 */
import React from 'react';
import { Helmet } from 'react-helmet';
// import StoreHome from "../../../Views/Desktop/Content/StoreHome/StoreHome";
// import StoreDetail from "../../../Views/Desktop/Content/StoreDetail/StoreDetail";
// import ProductDetail from "../../../Views/Desktop/Content/ProductDetail/ProductDetail";
import Modal from 'react-modal';
// import Modals from '../.././../Views/Desktop/Content/Modals/Modals';
import ScrollButton from '../../../UI/ScrollButton/ScrollButton';
import LoadingSpinner from '../../../UI/LoadingSpinner/LoadingSpinner';
import { ContentError } from '../../../Error/Shared/ContentError';
// import Layouts from '../../../Views/Desktop/Content/Layouts/Layouts';
// import { ChangeCountry } from "../../../Views/Shared/Content/ChangeCountry/ChangeCountry";
// import { ContactUs } from "../../../Views/Shared/Content/ContactUs/ContactUs";

import loadable from '@loadable/component';
const Modals = loadable(() => import(/* webpackChunkName: "desktopModals" */ '../.././../Views/Desktop/Content/Modals/Modals'));

export function ContentContainer(props) {
		var currentContentSwitchPath = props.passedAppFunctions.contentSwitchPath;
		if (props.contentData.JSONError) currentContentSwitchPath = 'JSONError';

		var Layouts = props.passedAppFunctions.chunks.Layouts;
		var ProductDetail = props.passedAppFunctions.chunks.ProductDetail;
		var StoreDetail = props.passedAppFunctions.chunks.StoreDetail;
		var StoreHome = props.passedAppFunctions.chunks.StoreHome;
		var ChangeCountry = props.passedAppFunctions.chunks.ChangeCountry;
		var ContactUs = props.passedAppFunctions.chunks.ContactUs;
		var Contest = props.passedAppFunctions.chunks.Contest;
		var StorePurchaseReturns = props.passedAppFunctions.chunks.StorePurchaseReturns;
		var Subscriptions = props.passedAppFunctions.chunks.Subscriptions;

		return (
			<main id='content_wrapper' className='p-t-10'>
				<div className={`${props.passedAppFunctions.siteFormat == 'tablet' ? 'container' : 'container-fluid'}`}>
					{
						{
							'/stores/:stateName/:name/:storeNum': <StoreDetail contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/stores/:name/:storeNum': <StoreDetail contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/stores': <StoreHome contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/stores/:stateName': <StoreHome contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/p/:name/:productId': <ProductDetail contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/p/:name/:productId/outfit': <ProductDetail contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/p/:name/:productId/sale': <ProductDetail contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/brand/:manufacturer': <Layouts contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/brand/:manufacturer/:categoryName': <Layouts contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/brand/:manufacturer/:categoryName/:pathModifier': (
								<Layouts contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />
							),
							'/c/:pathToken': <Layouts contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/c/:pathToken/:pathModifier': <Layouts contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/c/:pathToken/:pathModifier/:color': <Layouts contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/search-term/:searchTerm': <Layouts contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,

							'/search-term/:searchTerm/:categoryName': <Layouts contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/search-term/:searchTerm/:categoryName/:pathModifier': (
								<Layouts contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />
							),
							'/': <Layouts contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/chanel/c/:pathToken': <Layouts contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/changecountry': <ChangeCountry contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/contactus': <ContactUs contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/returns': <StorePurchaseReturns contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							'/member/subscriptions': <Subscriptions contentData={props.contentData} passedAppFunctions={props.passedAppFunctions} />,
							JSONError: (
								<div>
									<Helmet meta={[{ name: 'robots', content: 'noindex' }]} />
									<ContentError error={props.contentData.JSONError} />
								</div>
							),
						}[currentContentSwitchPath]
					}
				</div>
				{props.quickViewData ? (
					<div>
						<Modal
							isOpen={
								typeof props.quickViewData.contentData.productLayout != 'undefined' && props.quickViewData.contentData.productLayout != 'Loading'
							}
							className='QvModal__Content ReactModal__KeepOpenFor_OSS'
							overlayClassName='QvModal__Overlay ReactModal__KeepOpenFor_OSS'
							onRequestClose={props.passedAppFunctions.closeQuickView}
							onAfterOpen={props.passedAppFunctions.afterOpenModal}
							shouldReturnFocusAfterClose={true}
							aria={{ label: 'QuickView' }}>
							<ProductDetail contentData={props.quickViewData.contentData} passedAppFunctions={props.passedAppFunctions} />
						</Modal>
						{typeof props.quickViewData.contentData.productLayout == 'undefined' || props.quickViewData.contentData.productLayout == 'Loading' ? (
							<LoadingSpinner passedAppFunctions={props.passedAppFunctions} />
						) : (
							''
						)}
					</div>
				) : (
					''
				)}
				<If
					test={
						props.passedAppFunctions.modalInfo &&
						props.passedAppFunctions.modalInfo.modalType &&
						props.passedAppFunctions.modalInfo.modalType == 'Loading'
					}>
					<LoadingSpinner passedAppFunctions={props.passedAppFunctions} />
				</If>
				<If test={props.passedAppFunctions.modalInfo && props.passedAppFunctions.modalInfo.modalType}>
					<Modals passedAppFunctions={props.passedAppFunctions} />
				</If>
				{props.passedAppFunctions.transition ? <LoadingSpinner passedAppFunctions={props.passedAppFunctions} /> : ''}
				<ScrollButton showUnder={160}>
					<button className='btn-scroll--top'>
						<i className='icon i-btn-scroll-top'>
							<svg role='img' aria-labelledby='backToTop' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.77 25.39' width='16' height='16'>
								<title id='backToTop'>Return to top</title>
								<g data-name='Layer 2'>
									<g data-name='Layer 1'>
										<path d='M47.77,25.39a1,1,0,0,1-.71-.29L24.39,2.41,1.71,25.09A1,1,0,0,1,.29,23.68L23.68.29a1,1,0,0,1,1.41,0L48.48,23.68a1,1,0,0,1-.71,1.71Z' />
									</g>
								</g>
							</svg>
						</i>
					</button>
				</ScrollButton>
			</main>
		);
}

export default ContentContainer;

import React from 'react';
import SetInnerHTML from '../../../../UI/SetInnerHTML/SetInnerHTML';
import SharedTippyTop, { SharedTippyTopHeight } from '../../../Shared/Header/TippyTop/TippyTop';
require('./tippy-top.scss');


export class TippyTop extends SharedTippyTop{
    constructor(props){
        super(props);
    }
	render(){
		return(
			<If test={this.state.content} >
                <div className='perm-tippy-top desktop-perm-tippy-top' onTouchEnd={this.closeMenu}>
                    <SetInnerHTML className='perm-tippy-top-contents' innerHTML={this.state.content}  />
                </div>
            </If>
		);
	}
};

class TippyTopHeight extends SharedTippyTopHeight {
    constructor(props){
        super(props);
    }
    render(){
        return(
			<If test={this.state.height > 0} >
                <style>
                    {`
                        #my_dillards_bar_wrapper{top:${this.state.height}px!important;}
                        #menu_wrapper{top:${35 + this.state.height}px!important;}
                    `}
                </style>
            </If>
		);
    }
}

export default TippyTop;

export var menuData = [
  {
    value: 0,
    display: "Women",
    link: "women",
    manual_cm_sp: "Womens-_-header-_-nav"
  },
  {
    value: 1,
    display: "Juniors",
    link: "juniors",
    manual_cm_sp: "Juniors-_-header-_-nav"
  },
  {
    value: 4,
    display: "Shoes",
    link: "shoes",
    manual_cm_sp: "Shoes-_-header-_-nav"
  },
  {
    value: 5,
    display: "Handbags",
    link: "handbags",
    manual_cm_sp: "handbags-_-header-_-nav"
  },
  {
    value: 6,
    display: "Accessories",
    link: "accessories",
    manual_cm_sp: "accessories-_-header-_-nav"
  },
  {
    value: 7,
    display: "Lingerie",
    link: "lingerie",
    manual_cm_sp: "lingerie-_-header-_-nav"
  },
  {
    value: 9,
    display: "Beauty",
    link: "beauty",
    manual_cm_sp: "Beauty-_-header-_-nav"
  },
  {
    value: 2,
    display: "Men",
    link: "men",
    manual_cm_sp: "Mens-_-header-_-nav"
  },
  {
    value: 3,
    display: "Kids",
    link: "kids",
    manual_cm_sp: "Kids-_-header-_-nav"
  },
  {
    value: 8,
    display: "Home",
    link: "home",
    manual_cm_sp: "Home-_-header-_-nav"
  },
  {
    value: 9,
    display: "Dillard's Exclusive",
    link: "exclusive-brands",
    manual_cm_sp: "dillards+exclusive-_-header-_-nav"
  }
];

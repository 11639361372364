import React from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SharedSearch from '../../../Shared/Header/Search/Search';
import { SearchIcon } from '../../../../UI/Icons/Icons';
import { cleanTermForURL } from '../../../../../util/cleanTermForURL';

export class Search extends SharedSearch {
	constructor (props) {
		super(props);
		this.searchBtnRef = React.createRef();
		this.exBtnRef = React.createRef();
		this.searchInputRef = React.createRef();
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.checkCurrentSearchTerm = this.checkCurrentSearchTerm.bind(this);
		this.clearIconFocus = this.clearIconFocus.bind(this);
		this.searchInputFocus = this.searchInputFocus.bind(this);
		this.suggestionsTab = this.suggestionsTab.bind(this);
		this.checkForClear = this.checkForClear.bind(this);
	}

	checkCurrentSearchTerm() {
		if (this.state.searchTerm == "null") {
			this.state.searchTerm = "";
		} else {
			if (this.state.suggestionView.entry[0] !== undefined) {
				if (!this.state.searchBoxActive && (this.state.suggestionView.entry[0].responseURL.indexOf('/brand/') > -1 || this.state.suggestionView.entry[0].responseURL.indexOf('/stores/') > -1)) {
					this.clearSearchTerm();
				}
			}
		}
		this.searchInputFocus();
	}

	clearIconFocus () {
		this.exBtnRef.current.focus();
	}

	searchInputFocus () {
		this.searchInputRef.current.focus();
	}

	suggestionsTab () {
		this.clearIconFocus();
	}

	checkForClear = (event) => {
		var thisKeyCode = event.keyCode;
		if (thisKeyCode == 13) {
			this.clearSearchTerm();
			this.searchInputFocus();
			event.preventDefault();
		}
		if (thisKeyCode == 9) {
			event.preventDefault();
			this.checkCurrentSearchTerm();
			return false;
		}
	};

	handleKeyDown (event) {
		var thisKeyCode = event.keyCode;
		var keyDownsWeCareAbout = [38, 40, 9, 13, 27];
		if (keyDownsWeCareAbout.indexOf(thisKeyCode) > -1) {
			//don't immediately prevent default for enter.  Let handleSubmit handle this.
			if ((this.state.searchTerm).length <= 0 && thisKeyCode == 9) {
				document.querySelector('.btn-search-react').focus();
			}
			if ((this.state.searchTerm).length > 0 && thisKeyCode == 9 && (this.state.suggestionView.entry).length == 0) {
				this.clearIconFocus();
			}
			if ((this.state.suggestionView.entry).length > 0) {
				if ($('#suggestions-wrapper ul li:last-of-type').hasClass('highlighted-suggestion') && (thisKeyCode != 13)) {
					// On the last suggestion will return focus to the clear-search icon if keypress = tab key
					this.suggestionsTab();
				}
			}
				
			if (event.shiftKey && thisKeyCode == 9) {
				document.querySelector('.shoppingBagLink').focus();
			}
			if (thisKeyCode != 13) event.preventDefault();
			
			if (thisKeyCode == 38) {
				//UP ARROW
				if (this.state.highlightedSuggestion.number > 0) {
					var newHighlightEntry = this.state.suggestionView.entry[this.state.highlightedSuggestion.number - 1];
					this.setState({ highlightedSuggestion: { 'number': this.state.highlightedSuggestion.number - 1, 'url': newHighlightEntry.responseURL }, activeDescendant: newHighlightEntry.displayName + newHighlightEntry.responseURL});
				} else {
					this.setState({ highlightedSuggestion: { 'number': -1, 'url': undefined } });
					this.setState({ activeDescendant: ''});
				}
			} else if (thisKeyCode == 40) {
				//DOWN ARROW
				if ((this.state.suggestionView.entry.length - 1) > this.state.highlightedSuggestion.number) {
					var newHighlightEntry = this.state.suggestionView.entry[this.state.highlightedSuggestion.number + 1];
					this.setState({ highlightedSuggestion: { 'number': this.state.highlightedSuggestion.number + 1, 'url': newHighlightEntry.responseURL }, activeDescendant: newHighlightEntry.displayName + newHighlightEntry.responseURL});
				} 
			} else if (thisKeyCode == 9) {
				//TAB KEY
				if ((this.state.suggestionView.entry.length - 1) > this.state.highlightedSuggestion.number) {
					var newHighlightEntry = this.state.suggestionView.entry[this.state.highlightedSuggestion.number + 1];
					this.setState({ highlightedSuggestion: { 'number': this.state.highlightedSuggestion.number + 1, 'url': newHighlightEntry.responseURL }, activeDescendant: newHighlightEntry.displayName + newHighlightEntry.responseURL});
				} 
			} else if (thisKeyCode == 27) {
				//ESC KEY
				this.clearSearchTerm();
			} else if (thisKeyCode == 13) {
				//ENTER KEY
				this.handleSubmit(event);
			}
		}
		if (this.props.passedAppFunctions.siteFormat != 'mobile') {
			setTimeout(() => {
				var headerClearSearch = document.querySelectorAll('.headerClearSearch');
				var headerSearchButton = document.querySelector('.headerSearchButton');
				if (headerClearSearch.length > 0) {
					headerSearchButton.style.display = 'none';
				} else {
					if (headerClearSearch.length == 0) {
						headerSearchButton.style.display = 'block';
					}
				}
			},200);
		}
	}

	render () {
		return (
			<div className={this.state.emptyTermSubmit ? 'search-area-react has-error' : 'search-area-react'}>
				<Form inline onSubmit={this.handleSubmit} action={this.state.highlightedSuggestion.url || this.state.registryUrl || `/search-term/${cleanTermForURL(this.state.searchTerm)}`} method='GET' >
					<FormGroup>
						{this.state.emptyTermSubmit
							?
							<label id='siteSearchLabel' htmlFor="headerSiteSearch" className="sr-only">Enter a valid search term</label>
							:
							<label id='siteSearchLabel' htmlFor="headerSiteSearch" className="sr-only">Search</label>
						}
						<span className="sr-only">When typing in this field, a list of search results will appear and be automatically updated as you type.</span>
						<input ref={this.searchInputRef} 
							tabIndex='0' 
							id="headerSiteSearch" 
							placeholder={this.state.emptyTermSubmit ? 'Enter a valid search term' : 'What are you shopping for?'} 
							type='text' 
							role="textbox" 
							autoComplete="off" autoCorrect="off" 
							autoCapitalize="off" 
							spellCheck="false" 
							value={this.state.searchTerm} 
							onKeyDown={this.handleKeyDown} 
							onChange={this.handleChange} 
							onFocus={this.handleFocus} 
							onBlur={this.handleBlur} 
							onClick={this.searchAreaFocus} 
							className='form-control search-input-react search-enabled' 
							aria-autocomplete="list" 
    						aria-controls="results"
    						aria-haspopup="listbox" 
  							aria-expanded={this.state.ariaExpanded}
							aria-activedescendant={!this.state.hasLooped ? this.state.activeDescendant : ''} />
						{this.state.searchTerm.length > 0 ?
							<button type="button" aria-label="Close" ref={this.exBtnRef} className={this.props.noSearchResultsFound ? 'clearSearchX' : 'clearSearchX headerClearSearch'} onClick={this.clearSearchTerm} onKeyDown={this.checkForClear} ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.77 48.77"><title>close</title><g data-name="Layer 2"><g data-name="Layer 1"><path d="M25.8,24.39,48.48,1.71A1,1,0,0,0,47.07.29L24.39,23,1.71.29A1,1,0,0,0,.29,1.71L23,24.39.29,47.07a1,1,0,1,0,1.41,1.41L24.39,25.8,47.07,48.48a1,1,0,0,0,1.41-1.41Z" /></g></g></svg></button>
							: ''
						}
						{this.props.passedAppFunctions.siteFormat != 'mobile' ?
							<Button data-twist='search-button' className={this.props.noSearchResultsFound ? 'btn-react btn-search-react' : 'btn-react btn-search-react headerSearchButton'} title='Search Dillards.com' type='submit' role="button">
								<SearchIcon ref={this.searchBtnRef} />
							</Button>
							: 
							<Button data-twist='search-button' className='btn-react btn-search-react' title='Search Dillards.com' type='submit' role="button">
								<SearchIcon ref={this.searchBtnRef} />
							</Button>
						}
						{typeof window != 'undefined' && window.DILLARDS.country != 'US' ? <Input type='hidden' name='facet' value='dil_shipinternational:Y' /> : ''}
						{this.state.searchName ? <Input type='hidden' name='searchName' value={this.state.searchName} /> : ''}
						{this.state.actionCode ? <Input type='hidden' name='actionCode' value={this.state.actionCode} /> : ''}
						<input type="hidden" value="Y" name="realSearch" />
					</FormGroup>
				</Form>
				{this.state.searchBoxActive && this.state.suggestionView.entry.length > 0 ? 
					<div id='suggestions-wrapper' className={this.state.searchHistoryActive ? 'recentSearchesReact' : ''} role="region" aria-label="search results">
						<If test={this.state.searchHistoryActive} >
							<h4>Recent Searches</h4>
							<button type="button" aria-label="Clear" className="clearRecent" onClick={this.clearSearchHist} onTouchStart={this.handleLinkDown}
								onMouseDown={this.handleLinkDown}
								onMouseUp={this.handleLinkUp} >Clear</button>
						</If>
						<span className="results-count sr-only" aria-live="assertive">{this.state.suggestionView.entry.length != 1 ? this.state.suggestionView.entry.length + " results" : this.state.suggestionView.entry.length + " result"}</span>
						<ul id="results" 
							role="listbox" 
							aria-labelledby='siteSearchLabel'>
							{this.state.suggestionView.entry.map((entry, index) =>
								<li id={`${entry.displayName}${entry.responseURL}`} 
									key={`${entry.displayName}${entry.responseURL}`} 
									className={index == this.state.highlightedSuggestion.number ? 'highlighted-suggestion' : ''}
									onTouchStart={this.handleLinkDown}
									onMouseDown={this.handleLinkDown}
									onClick={this.handleLinkUp}
									onMouseUp={this.handleLinkUp}
									onKeyDown={this.suggestionsTab}
									role="option"
									>
									<Choose>
										<When test={entry.responseURL.indexOf('/brand/') > -1 || entry.responseURL.indexOf('/stores/') > -1} >
											<Link className="autoSuggest" onClick={this.clearSearchTerm} data-cleanterm={entry.cleanTerm} data-cmtracking={entry.cmForLinkClick}
												to=
												{{
													pathname: `${entry.responseURL}`,
													state: { typeAheadClicked: "Y" }
												}}
												dangerouslySetInnerHTML={{ __html: entry.displayName }} ></Link>
										</When>
										<Otherwise>
											<Link className="autoSuggest" onClick={this.trackClick} data-cleanterm={entry.cleanTerm || entry.displayName} data-cmtracking={entry.cmForLinkClick}
												to=
												{{
													pathname: `${entry.responseURL}`,
													state: { typeAheadClicked: 'Y' }
												}}
												dangerouslySetInnerHTML={{ __html: entry.displayName }} ></Link>
										</Otherwise>
									</Choose>
								</li>
							)}
						</ul>
					</div>
					:
					''
				}
				<script type="application/ld+json"
					suppressHydrationWarning={true}
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(
							{
								"@context": "http://schema.org",
								"@type": "WebSite",
								"url": "https://www.dillards.com",
								"potentialAction": {
									"@type": "SearchAction",
									"target": "https://www.dillards.com/search-term/{search_term_string}",
									"query-input": "required name=search_term_string"
								}
							}
						)
					}}>
				</script>
			</div>
		);
	}
};

export default Search;

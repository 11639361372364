import React from 'react';
import { SharedError } from '../Shared/SharedError';

export class FooterError extends SharedError {
    constructor(props) {
        super(props);
    }
    render() {
        if (this.state.error) {
            return (
                <section id='footer_wrapper'>
                    <footer id='footer' className='footer'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div id='customer-service' className='col-md-3 col-sm-6 footer-content-section footer__contentSection'>
                                    <section>
                                        <h6 data-twist='footer-customer-service-header'>Customer Service</h6>
                                    </section>
                                    <section className='footer-links'>
                                        <ul className='hide-when-international'>

                                            <li data-twist='footer-customer-service-telephone'>Call 1-800-DILLARD (800-345-5273)</li>
                                            <li data-twist='footer-customer-service-hours-1'>Monday-Saturday: 7AM-Midnight <abbr className='timezone'>CST</abbr></li>
                                            <li data-twist='footer-customer-service-hours-2'>Sunday: 9AM-7PM <abbr className='timezone'>CST</abbr></li>

                                            <li></li>

                                            <li>
                                                <a data-twist='footer-contact-us' href='/contactus' manual_cm_sp='customerservice-_-ContactUS-_-null'>Contact Us Via Email</a>
                                            </li>

                                            <li>
                                                <a data-twist='footer-check-order-status' href='/webapp/wcs/stores/servlet/OrderStatusLoginChk?storeId=301&catalogId=301&langId=-1' manual_cm_sp='customerservice-_-orderstatus-_-null' rel='nofollow'>Order Status</a>
                                            </li>

                                            <li>
                                                <button type="button" data-twist='footer-return-policy' className='btn-tertiary' onclick='Dillards.triggerModal(&quot;/static/html/modals/_returns.html&quot;,&quot;Online Return Policy&quot;,&quot;.modal&quot;)' data-relative-href='c/faqs-notices-policies?' data-cmsp='customerservice-_-returnpolicy-_-null'>Return Policy</button>
                                            </li>

                                            <li>
                                                <a data-twist='footer-faq' href='/c/faqs-notices-policies' manual_cm_sp='customerservice-_-faq-_-null'>FAQ, Notices & Policies</a>
                                            </li>

                                            <li>
                                                <a data-twist='footer-california' href='/c/faqs-notices-policies#q=policies-notices-question-california' manual_cm_sp='customerservice-_-CATransparencyAct-_-null '>California Transparency in Supply Chains Act</a>
                                            </li>

                                            <li>
                                                <a data-twist='footer-recalls' href='/c/faqs-notices-policies' manual_cm_sp='customerservice-_-Recalls-_-null'>Product Recalls</a>
                                            </li>

                                        </ul>

                                        <ul className='show-when-international'>
                                            <li data-twist='footer-customer-service-international-number-1'>
                                                <a href='tel:1-817-831-5482'>Call 1-817-831-5482</a></li>
                                            <li data-twist='footer-customer-service-international-hours-1'>Monday-Saturday: 7AM-10PM GMT-6</li>
                                            <li data-twist='footer-customer-service-international-hours-2'>Sunday: 12PM-6PM GMT-6</li>
                                            <li></li>
                                        </ul>

                                        <ul className='show-when-international'>
                                            <li data-twist='footer-international-contact-us'>
                                                <a href='/contactus' manual_cm_sp='customerservice-_-ContactUS-_-null'>Contact Us Via Email</a>
                                            </li>

                                            <li>
                                                <a data-twist='footer-international-check-order' href='https://tracking.borderfree.com'>Check Order Status</a>
                                            </li>

                                            <li>
                                                <a data-twist='footer-international-faq' href='/c/faqs-notices-policies'>FAQ, Notices & Policies</a>
                                            </li>

                                            <li>
                                                <a data-twist='footer-international-california' href='/c/faqs-notices-policies?#q=int-policies-notices-question-california' manual_cm_sp='Footer-_-Int-_-CAtransparencyAct'>California Transparency in Supply Chains Act</a>
                                            </li>

                                            <li className='show-when-international'>
                                                <a data-twist='footer-recalls' href='/c/faqs-notices-policies' manual_cm_sp='customerservice-_-Recalls-_-null'>Product Recalls</a>
                                            </li>
                                        </ul>
                                    </section>

                                </div>
                                <div id='credit-card-and-company' className='col-md-3 col-sm-6 footer-content-section footer__contentSection'>
                                    <section className='hide-when-international'>
                                        <h6>Dillard's Credit Card</h6>
                                    </section>
                                    <section className='footer-links hide-when-international'>
                                        <ul>
                                            <li>
                                                <button className='exit-link wf-exit-link btn-tertiary' data-exitlink='Footer Pay Bill Online' data-toggle='modal' data-target='.wf-speedbump' data-modal-title='Page Loading' data-modal-body='wellsFargoSpeedbump({"wellsLink": "/credit-services/online-access"})' manual_cm_sp='customerservice-_-PayMyBill-_-null' target='_blank'>Pay Bill Online</button>
                                            </li>
                                            <li>
                                                <a className='exit-link' data-exitlink='Footer View Credit Account' href='/c/DillardsCard' manual_cm_sp='Footer-_-ViewCreditAccount-_-CreditServices' target='_blank'>View Credit Account</a>
                                            </li>
                                        </ul>
                                    </section>

                                    <section>
                                        <h6>Company</h6>
                                    </section>
                                    <section className='footer-links'>
                                        <ul>
                                            <li>
                                                <a target='_blank' className='exit-link yesOL' data-exitlink='Footer Investor Relations' href='https://investor.dillards.com/overview/default.aspx'>Investor Relations</a>
                                            </li>
                                            <li>
                                                <a target='_blank' className='exit-link yesOL' data-exitlink='Footer Careers' href='https://careers.dillards.com/Careers/'>Careers</a>
                                            </li>
                                            <li className='hide-when-international'>
                                                <a href='/c/faqs-notices-policies?#q=policies-notices-question-privacy-policy' manual_cm_sp='customerservice-_-privacy+policy-_-null'>Dillard&#39s Privacy Policy</a>
                                            </li>
                                            <li className='show-when-international'>
                                                <a href='/c/faqs-notices-policies?#q=int-policies-notices-question-privacy-policy' manual_cm_sp='customerservice-_-privacy+policy-_-null'>Dillard&#39s Privacy Policy</a>
                                            </li>
                                        </ul>
                                    </section>

                                </div>
                                <div id='more-way-to-shop' className='col-md-3 col-sm-6 footer-content-section footer__contentSection'>
                                    <section>
                                        <h6 data-twist='footer-more-ways-to-shop-header'>More Ways To Shop</h6>
                                    </section>
                                    <section>
                                        <ul>
                                            <li className='hide-when-international'>
                                                <a data-twist='footer-registry' href='/registry' manual_cm_sp='EspotFooterBar-_-Registry-_-TextLink0212'>Registry - Wedding, Baby, and Gift</a>

                                            </li>
                                            <li className='hide-when-international'>
                                                <a data-twist='footer-gift-card' href='/c/giftcard' manual_cm_sp='EspotFooterBar-_-GiftCards-_-TextLink0212'> Gift Cards</a>
                                            </li>

                                            <li className='hide-when-international'>
                                                <a data-twist='footer-store-locator' href='/stores' manual_cm_sp='EspotFooterBar-_-StoreLocator-_-TextLink0212'>Store Locations</a>
                                            </li>

                                            <li id='flag-icon-footer'>
                                                <a href='/changecountry' manual_cm_sp='Footer-_-Int-_-ChangeCountry'>
                                                    <i data-twist='footer-change-country' alt='Flag Icon'><span id='footer-flag-img-span'></span></i><span>Change Country</span>
                                                </a>
                                            </li>
                                            <li className='show-when-international'>
                                                <button type="button" className='btn-tertiary' data-toggle='modal' data-target='.apo-fpo' data-modal-title='Shipping to an APO or FPO address?' data-modal-body='/assets/_APO_FPO-modal.html'>APO / FPO</button>
                                            </li>
                                        </ul>
                                    </section>

                                </div>
                                <div id='connect-follow' className='col-md-3 col-sm-6 footer-content-section footer__contentSection'>
                                </div>
                            </div>
                        </div>
                    </footer>
                    <div id='footer_tippy_bottom' className='footer__tippyBottom'>
                        <div className={`${this.props.passedAppFunctions.siteFormat == 'tablet' ? 'container' : 'container-fluid'}`}>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <p>Prices and sale offers may vary by store location, including Dillards.com, and are subject to change. &copy; {new Date().getFullYear()} Dillard's</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderHiddenError('Footer')}
                </section>
            );
        } else {
            return this.props.children;
        }
    }
}

export default FooterError;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import HoverIntent from 'react-hoverintent';
import { ArrowDownIcon, EmptyShoppingBag } from '../../../../UI/Icons/Icons';
export class MyDillardsBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMyDillardsLogo: false,
			showAccountDD: false,
			loaded: false,
		};
		this.state.mouseover = false;
		this.onMouseOver = this.onMouseOver.bind(this);
		this.onMouseOut = this.onMouseOut.bind(this);
		this.showAccountDD = this.showAccountDD.bind(this);
		this.hideAccountDD = this.hideAccountDD.bind(this);
		this.toggleAccountDD = this.toggleAccountDD.bind(this);
		this.handleShowDillardsLogo = this.handleShowDillardsLogo.bind(this);
	}

	onMouseOver() {
		if (window.DILLARDS.country == 'US') this.setState({ mouseover: true });
	}

	onMouseOut() {
		this.setState({ mouseover: false });
	}

	preventDoubleClick() {
		if (document.getElementsByClassName('shopping-bag-count-container')[0]) {
			document.getElementsByClassName('shopping-bag-count-container')[0].style.pointerEvents = 'none';
		} 
	}

	toggleAccountDD() {
		this.setState({ showAccountDD: !this.state.showAccountDD });
	}

	showAccountDD() {
		this.setState({ showAccountDD: true });
	}

	hideAccountDD() {
		this.setState({ showAccountDD: false });
	}

	handleShowDillardsLogo() {
		if (this.props.passedAppFunctions.siteFormat == 'desktop') {
			const initialPosition = window.pageYOffset || document.documentElement.scrollTop;
			if (document.querySelector('#my_dillards_bar_wrapper').getBoundingClientRect().top <= 1 && initialPosition > 0) {
				if (!this.state.showMyDillardsLogo) {
					this.setState({ showMyDillardsLogo: true });
				}
			} else {
				if (this.state.showMyDillardsLogo) {
					this.setState({ showMyDillardsLogo: false });
				}
			}
		}
	}

	handleLoadState() {
		this.setState({loaded: true});
   }

	componentDidMount() {
		this.handleLoadState();
		if (this.props.passedAppFunctions.siteFormat == 'desktop') {
			this.handleShowDillardsLogo();
		}
		window.addEventListener('scroll', this.handleShowDillardsLogo);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleShowDillardsLogo);
	}

	render() {
		let { mouseover } = this.state.mouseover;

		return (
			this.state.loaded ?
			<div
				id='my_dillards_bar_wrapper'
				tabIndex='-1'
				className={`dillardsBarPromo ${this.state.showMyDillardsLogo ? 'backgroundBlack' : ''}`}
				onTouchEnd={this.props.closeMenu}>
				<div className={`${this.props.passedAppFunctions.siteFormat == 'tablet' ? 'container' : 'container-fluid'}`}>
					<div className='mdbContainer__row row'>
						<div className='col-sm-12'>
							<ul className='barItems'>
								{this.props.passedAppFunctions.siteFormat == 'desktop' ? (
									<Link to='/' rel='nofollow' className='logo-spot'>
										<img className='myDillardsLogo' src='/assets/img/logos/smallDillardsLogo.png' width='59' height='15' alt="Dillard's Logo"></img>
									</Link>
								) : (
									''
								)}
								<If test={this.state.showMyDillardsLogo == false}>
									<li className='barItems__li'>
										<Link to='/c/sale-clearance' manual_cm_sp='Header-_-SaleClearance-_-TextLink' className='sale-clearance text--clearance'>
											Sale and Clearance
										</Link>
									</li>
									<li className='barItems__li'>
										<Link to='/c/shopbybrand' manual_cm_sp='Header-_-ShopByBrand-_-TextLink'>
											Shop By Brand
										</Link>
									</li>
									<li className='barItems__li'>
										<Link to='/c/limited-availability' manual_cm_sp='Header-_-LimitedAvailability-_-TextLink' className='text--limitedAvailability'>
											Limited Availability
										</Link>
									</li>
								</If>
								<HoverIntent onMouseOver={this.showAccountDD} onMouseOut={this.hideAccountDD}>
									<li className='barItems__li hide-when-international myAccountDD'>
										<Link
											to='/webapp/wcs/stores/servlet/LogonForm?storeId=301&langId=-1&catalogId=301'
											rel='nofollow'
											aria-label='login'
											aria-describedby='login menu'>
											{this.props.myDillardsInfo.userInformation.userId == '-1002' ? 'Login' : 'My Account'}
										</Link>
										<button
											onClick={this.toggleAccountDD}
											className='btn-tertiary'
											type='button'
											aria-label='Login'
											aria-controls='accountDDContainer'
											aria-expanded={this.state.showAccountDD ? 'true' : 'false'}>
											<ArrowDownIcon downArrowTitleId='loginMenu' downArrowTitleDesc='Login Menu Dropdown' />
										</button>
										<div id='accountDDContainer' className={`accountDDContainer ${this.state.showAccountDD ? 'accountDDShowContainer' : ''}`}>
											<div className='accountDD'>
												<ul>
													{this.props.myDillardsInfo.userInformation.userId == '-1002' ? (
														<li className='hide-when-international login'>
															<Link to='/webapp/wcs/stores/servlet/LogonForm?storeId=301&langId=-1&catalogId=301' rel='nofollow'>
																Login
															</Link>
														</li>
													) : (
														''
													)}
													{this.props.myDillardsInfo.userInformation.userId == '-1002' || this.props.siteFormat == 'tablet' ? (
														<li className='hide-when-international'>
															<Link to='/webapp/wcs/stores/servlet/LogonForm?storeId=301&langId=-1&catalogId=301' rel='nofollow'>
																My Dillard's Account
															</Link>
														</li>
													) : (
														<li className='hide-when-international'>
															<a href='/webapp/wcs/stores/servlet/LogonForm?storeId=301&langId=-1&catalogId=301' rel='nofollow'>
																My Dillard's Account
															</a>
														</li>
													)}
													{this.props.myDillardsInfo.userInformation.userId == '-1002' ? (
														<li className='hide-when-international'>
															<Link to='/webapp/wcs/stores/servlet/LogonForm?storeId=301&catalogId=301&langId=-1&rURL=InterestItemDisplay' rel='nofollow'>
																My Wish List
															</Link>
														</li>
													) : (
														<li className='hide-when-international'>
															<Link to='/webapp/wcs/stores/servlet/InterestItemDisplay?storeId=301&catalogId=301&langId=-1' rel='nofollow'>
																My Wish List
															</Link>
														</li>
													)}
													{this.props.myDillardsInfo.userInformation.userId != '-1002' && this.props.myDillardsInfo.userInformation.DefaultRegistry ? (
														<li className='hide-when-international'>
															<Link
																to={`/webapp/wcs/stores/servlet/RegistryManageProducts?catalogId=301&langId=-1&storeId=301&actionCode=M&registryNumber=${this.props.myDillardsInfo.userInformation.DefaultRegistry}`}
																rel='nofollow'>
																My Registry
															</Link>
														</li>
													) : (
														''
													)}
													{this.props.myDillardsInfo.userInformation.userId == '-1002' ? (
														''
													) : (
														<li className='hide-when-international logout'>
															<Link to={`/webapp/wcs/stores/servlet/Logoff?URL=/`} rel='nofollow'>
																Logout
															</Link>
														</li>
													)}
												</ul>
											</div>
										</div>
									</li>
								</HoverIntent>

								{
									<li className='barItems__li barItems__li--noborders shopping-bag-count-container'>
										<Link
											onClick={this.preventDoubleClick}
											style={this.state.disableClick ? { pointerEvents: 'none' } : {}}
											className='d-inline-block shoppingBagLink'
											to='/webapp/wcs/stores/servlet/OrderItemDisplay?storeId=301&catalogId=301&langId=-1'
											rel='nofollow'>
											<EmptyShoppingBag />
											<span className='cartItemCount'>{this.props.myDillardsInfo.miniCartInfo.cartItemCount}</span>
											<span className='sr-only'> items in cart</span>
										</Link>
									</li>
								}
							</ul>
						</div>
					</div>
				</div>
			</div>
			:
			<div
				id='my_dillards_bar_wrapper'
				tabIndex='-1'
				className={`dillardsBarPromo ${this.state.showMyDillardsLogo ? 'backgroundBlack' : ''}`}
				onTouchEnd={this.props.closeMenu}>
			</div>
		);
	}
}

MyDillardsBar.propTypes = {
	handleMyDillardsBoxClick: PropTypes.func,
};

export default MyDillardsBar;
